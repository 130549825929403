import React, { useState } from "react"
import {Link} from "gatsby"
import {navigate} from "gatsby"
import SiteNav, {ContentGroup} from 'react-site-nav';

import Sun from "../images/sun.svg"
import Vertical from "../images/vertical.svg"
import Cannabis from "../images/cannabis.svg"
import Wine from "../images/wine.svg"
import Seasonal from "../images/seasonal.svg"
import AI from "../images/ai.svg"
import IoT from "../images/iot.svg"
import Cloud from "../images/cloud.svg"

import "./css/header.scss"

const Header = ({backgroundColor, headerColor, LogoColor}) => {
  const [mobileHeader, setMobileHeader] = useState(false);

  return (
    <>
      <header style={{backgroundColor: backgroundColor}}>
        <div className="header-nav header-items">
          <SiteNav
            color={headerColor}
            background='transparent'
            fontSize="20"
            contentTop="0"
            >
            <ContentGroup title="Products" height="220" width="245">
              <div className="dropdown-content">
                <div className="product-content">
                  <div className="product">
                    <AI />
                    <h6>MasterGrower AI</h6>
                  </div>
                  <div className="product">
                    <IoT />
                    <h6>Automation IoT</h6>
                  </div>
                  <div className="product">
                    <Cloud />
                    <h6>Grower's Cloud</h6>
                  </div>
                </div>
              </div>
            </ContentGroup>
            <ContentGroup title="Solutions" height="340" width="520">
              <div className="dropdown-content">
                <div className="solutions-content">
                  <div className="industries solution-item">
                    <h4>Industries</h4>
                    <div className="industry">
                      <Sun />
                      <h6>Outdoor</h6>
                    </div>
                    <div className="industry">
                      <Vertical />
                      <h6>Vertical</h6>
                    </div>
                    <div className="industry">
                      <Cannabis />
                      <h6>Cannabis</h6>
                    </div>
                    <div className="industry">
                      <Wine />
                      <h6>Wine</h6>
                    </div>
                    <div className="industry">
                      <Seasonal />
                      <h6>Seasonal</h6>
                    </div>
                  </div>
                  <div className="use-cases solution-item">
                    <h4>Use Cases</h4>
                    <h6>Crop yield optimization</h6>
                    <h6>Soil management & analysis</h6>
                    <h6>Better water conservation</h6>
                    <h6>Reduction of greenhouse emissions</h6>
                    <h6>Organizational & operational efficencies</h6>
                  </div>
                </div>
              </div>
            </ContentGroup>
            <ContentGroup title="Company" height="220" width="180">
              <div className="dropdown-content">
                <div className="company-content">
                  <div className="company-item">
                    <Link to="/about">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
                        <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
                      </svg>
                      <h6>
                        About
                      </h6>
                    </Link>
                  </div>
                  <div className="company-item">
                    <Link to="/newsroom">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-newspaper" viewBox="0 0 16 16">
                        <path d="M0 2.5A1.5 1.5 0 0 1 1.5 1h11A1.5 1.5 0 0 1 14 2.5v10.528c0 .3-.05.654-.238.972h.738a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 1 1 0v9a1.5 1.5 0 0 1-1.5 1.5H1.497A1.497 1.497 0 0 1 0 13.5v-11zM12 14c.37 0 .654-.211.853-.441.092-.106.147-.279.147-.531V2.5a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0-.5.5v11c0 .278.223.5.497.5H12z"/>
                        <path d="M2 3h10v2H2V3zm0 3h4v3H2V6zm0 4h4v1H2v-1zm0 2h4v1H2v-1zm5-6h2v1H7V6zm3 0h2v1h-2V6zM7 8h2v1H7V8zm3 0h2v1h-2V8zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1z"/>
                      </svg>
                      <h6>
                        Newsroom
                      </h6>
                    </Link>
                  </div>
                  <div className="company-item">
                    <Link>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-briefcase" viewBox="0 0 16 16">
                        <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z"/>
                      </svg>
                      <h6>
                        Careers
                      </h6>
                    </Link>
                  </div>
                  <div className="company-item">
                    <Link to="/contact">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                      </svg>
                      <h6>
                        Contact Us
                      </h6>
                    </Link>
                  </div>
                </div>
              </div>
            </ContentGroup>
            <ContentGroup title="Pricing" rootUrl="/pricing">
            </ContentGroup>
          </SiteNav>
        </div>
        <div className="header-container">
          <div className="header-container-nav">
            <h1 className="header-logo header-items"><Link to="/" style={{ color: LogoColor }}>InstaLeaf</Link></h1>
            <nav className="header-nav-cta header-items">
              <ul className="header-nav-list cta-list">
                <li className="contact-sales-li"><Link to="/contact"><button className="contact-sales" style={{ color: headerColor }}>Contact Sales</button></Link></li>
                <li className="sign-in-li">
                  <a href="https://app.instaleaf-ag.com">
                    <button className="sign-in" style={{ color: headerColor }}>
                      Sign In
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                      </svg>
                    </button>
                  </a>
                </li>
              </ul>
            </nav>
            <nav className="header-nav-menu header-items" onClick={() => setMobileHeader(!mobileHeader)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
              </svg>
            </nav>
          </div>
        </div>
      </header>
      {
        mobileHeader &&
        <>
          <div className="mobile-header">
            <div className="header-section">
              <h4>Products</h4>
              <div className="product">
                <AI />
                <h6>MasterGrower AI</h6>
              </div>
              <div className="product">
                <IoT />
                <h6>Automation IoT</h6>
              </div>
              <div className="product">
                <Cloud />
                <h6>Grower's Cloud</h6>
              </div>
            </div>

            <div className="header-section">
              <div className="industries solution-item">
                <h4>Industries</h4>
                <div className="industry">
                  <Sun />
                  <h6>Outdoor</h6>
                </div>
                <div className="industry">
                  <Vertical />
                  <h6>Vertical</h6>
                </div>
                <div className="industry">
                  <Cannabis />
                  <h6>Cannabis</h6>
                </div>
                <div className="industry">
                  <Wine />
                  <h6>Wine</h6>
                </div>
                <div className="industry">
                  <Seasonal />
                  <h6>Seasonal</h6>
                </div>
              </div>
            </div>
            <div className="header-section">
              <h4>Company</h4>
              <div className="company-item">
                <Link to="/about">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
                    <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
                  </svg>
                  <h6>
                    About
                  </h6>
                </Link>
              </div>
              <div className="company-item">
                <Link to="/newsroom">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-newspaper" viewBox="0 0 16 16">
                    <path d="M0 2.5A1.5 1.5 0 0 1 1.5 1h11A1.5 1.5 0 0 1 14 2.5v10.528c0 .3-.05.654-.238.972h.738a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 1 1 0v9a1.5 1.5 0 0 1-1.5 1.5H1.497A1.497 1.497 0 0 1 0 13.5v-11zM12 14c.37 0 .654-.211.853-.441.092-.106.147-.279.147-.531V2.5a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0-.5.5v11c0 .278.223.5.497.5H12z"/>
                    <path d="M2 3h10v2H2V3zm0 3h4v3H2V6zm0 4h4v1H2v-1zm0 2h4v1H2v-1zm5-6h2v1H7V6zm3 0h2v1h-2V6zM7 8h2v1H7V8zm3 0h2v1h-2V8zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1z"/>
                  </svg>
                  <h6>
                    Newsroom
                  </h6>
                </Link>
              </div>
              <div className="company-item">
                <Link>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-briefcase" viewBox="0 0 16 16">
                    <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z"/>
                  </svg>
                  <h6>
                    Careers
                  </h6>
                </Link>
              </div>
              <div className="company-item">
                <Link to="/contact">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                  </svg>
                  <h6>
                    Contact Us
                  </h6>
                </Link>
              </div>
            </div>

            <div className="header-section">
              <ul className="header-nav-list cta-list">
                <li className="contact-sales-li"><Link to="/contact"><button className="contact-sales" style={{ color: headerColor }}>Contact Sales</button></Link></li>
                <li className="sign-in-li">
                  <a href="https://app.instaleaf-ag.com">
                    <button className="sign-in" style={{ color: headerColor }}>
                      Sign In
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                      </svg>
                    </button>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </>
      }
    </>
  )
}

export default Header
