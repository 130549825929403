import * as React from "react"
import Header from "./header.js"
import Footer from "./footer.js"
import { Helmet } from "react-helmet"

const Page = ({children, headerColor, headerLogoColor, backgroundColor}) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>AI Agriculture | InstaLeaf</title>
        <link rel="canonical" href="https://instaleaf.ai" />
        <link rel="description" href="AI agriculture." />
      </Helmet>
      <Header backgroundColor={backgroundColor} headerColor={headerColor} LogoColor={headerLogoColor} />
      {children}
      <Footer />
    </>
  )
}

Page.defaultProps = {
  headerColor: '#2C3A47',
  headerLogoColor: '#2ecc71'
};

export default Page
