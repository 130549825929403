import * as React from "react"
import {Link} from "gatsby"
import {navigate} from "gatsby"

import "./css/footer.scss"

const Footer = ({isFixed}) => {
  return (
    <>
      <footer>
        <div className="footer-container">
          <div className="footer-section-layout">
            <div className="footer-section-column edge-left">
              <h1 className="footer-logo">InstaLeaf</h1>
              <h6 className="copyright">&#169; InstaLeaf</h6>
            </div>
            <div className="footer-section-column small-edge-right">
              <section className="footer-section-list">
                <h6>Products</h6>
                <ul>
                  <li>MasterGrower AI</li>
                  <li>Automation IoT</li>
                  <li>Grow Portal</li>
                  {/*<li>Mobile Grow</li>*/}
                </ul>
              </section>
            </div>
            <div className="footer-section-column small-edge-left">
              <section className="footer-section-list">
                <h6>Solutions</h6>
                <ul>
                  <li>Enterprise</li>
                  <li>Growers</li>
                </ul>
              </section>
            </div>
            <div className="footer-section-column edge-right">
              <section className="footer-section-list">
                <h6>Company</h6>
                <ul>
                  <li><Link to="/about">About</Link></li>
                  <li><Link to="/newsroom">Newsroom</Link></li>
                  <li><Link to="/pricing">Pricing</Link></li>
                  <li>Careers</li>
                  <li><Link to="/contact">Contact Us</Link></li>
                  <li><Link to="/privacyPolicy">Privacy Policy</Link></li>
                </ul>
              </section>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
